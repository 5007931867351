import Vue from "vue";
import Enumerable from "linq";
import { alertBox } from "../Script/Alert";
import { BaseUrl, SchoolType } from "../Script/BaseUrl";
import axios from "axios";
import url from "domurl";
import moment from "moment";

const s_object_html = `
<div>
<template v-for="obj in step_data.ObjectData" v-if="obj.ID==ReturnId()">

    <!-- 0 僅顯示資訊 -->
    <div class="form-group" v-if="obj.Object_Type_ID==0">
    </div>

    <!-- 12 取得公告結果 -->
    <div class="form-group" v-if="obj.Object_Type_ID==12">
        {{WarningMessage}}
        <div v-show="columnSettings.length>0" class="table-responsive">
            <table class="table align-items-center table-flush">
                <thead class="thead-light">
                    <tr>
                        <th>項目名稱</th>
                        <th>項目內容</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,index) in columnSettings">
                        <td v-html="item.column_name"></td>
                        <td v-for="res in dataSettings" v-if="res.user_result_column_position==item.position" v-html="res.data"></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <!-- 1 單行文字方塊 -->

    <!-- 地址選單，只有國中套用選單，國小保持輸入欄 -->
    <div class="w-full" v-bind:id="'object_'+obj.ID+'_div'" v-if="SchoolType == 2 && obj.Object_Type_ID==1 && obj.ID=='100000010'">
      <span class="font-bold w-full text-base 2xl:text-lg">{{obj.Title}}
        <span v-if="remind" v-html="remind"></span>
        <span v-if="obj.Required == true" class="text-red-600 text-xl font-bold">*</span>
      ：</span>
      <input class="w-full p-4 rounded bg-lightColor " :required="obj.Required" placeholder="例如：桃園市桃園市桃園區中正路1號（請注意，需要符合「就讀或戶籍設於桃園市」之申請資格）" type="text" v-model="obj.Data" autocomplete="new-user-typing-data">
      </div>

    <!-- Open ID 不能改名字 -->
    <div class="w-full" v-bind:id="'object_'+obj.ID+'_div'" v-else-if="userdata && obj.Object_Type_ID==1 && obj.ID=='100000001' && userdata.Account.includes('User_') ">
    <span class="font-bold w-full text-base 2xl:text-lg">{{obj.Title}}
      <span v-if="remind" v-html="remind"></span>
      <span v-if="obj.Required == true" class="text-red-600 text-xl font-bold">*</span>
    ：</span>
    <input class="w-full p-4 rounded bg-lightColor " :required="obj.Required" disabled type="text" v-model="obj.Data" autocomplete="new-user-typing-data">
    <span>{{ option }}</span>
    </div>

    <!-- 其他單行文字方塊 -->
    <div class="w-full" v-bind:id="'object_'+obj.ID+'_div'" v-else-if="obj.Object_Type_ID==1">
    <span class="font-bold w-full text-base 2xl:text-lg">{{obj.Title}}<span v-if="remind" v-html="remind"></span><span v-if="obj.Required == true" class="text-red-600 text-xl font-bold">*</span>：</span><input class="w-full p-4 rounded bg-lightColor " :required="obj.Required" placeholder="請輸入...." type="text" v-model="obj.Data" autocomplete="new-user-typing-data">
    </div>

    <!-- 2 多行文字方塊 -->
    <div class="w-full" v-bind:id="'object_'+obj.ID+'_div'" v-if="obj.Object_Type_ID==2">
    <span class="font-bold w-full text-base  2xl:text-lg  ">{{obj.Title}}<span v-if="obj.Required == true" class="text-red-600 text-xl font-bold">*</span>：</span><textarea class="w-full p-2 rounded bg-lightColor " :required="obj.Required" placeholder="請輸入...." v-model="obj.Data"></textarea>
    </div>

    <!-- 3 單選方塊  -->
    <!-- 7 階段選擇  -->
    <div class="w-full" v-bind:id="'object_'+obj.ID+'_div'" v-if="(obj.Object_Type_ID==3 || obj.Object_Type_ID==7) ">
      <span class="font-bold w-full text-base  2xl:text-lg  ">{{obj.Title}}<span v-if="obj.Required == true" class="text-red-600 text-xl font-bold">*</span>：</span>
      <!-- 國中  -->
      <div v-if="obj.ID == 100002001 || obj.ID == 200002001 || obj.ID == 300002001 || obj.ID == 400002001">
        ※可申請項目請參考
        <a  class=" p-1  text-blue-500  hover:text-blue-700 underline decoration-1 font-bold m-1 rounded-lg" :href="/Files/+obj.ID+'.docx'" download="特殊試場需求申請表">特殊試場需求申請表</a> 
         ，若勾選「有」請於後續步驟拍照或掃描上傳附件八申請表及相關佐證資料
      </div>
      <!-- 國小一般智能  -->
      <div v-if="obj.ID == 500001007">
        ※可申請項目請參考
        <a  class=" p-1  text-blue-500  hover:text-blue-700 underline decoration-1 font-bold m-1 rounded-lg" :href="/Files/+obj.ID+'.docx'" download="特殊試場需求申請表">特殊試場需求申請表</a> 
         ，若勾選「是」請於後續步驟拍照或掃描上傳附件八<span class="text-red-500 font-bold">核章後申請表</span>及相關佐證資料
      </div>
      <!-- 國小創造能力  -->
      <div v-if="obj.ID == 600002006">
        ※可申請項目請參考
        <a  class=" p-1  text-blue-500  hover:text-blue-700 underline decoration-1 font-bold m-1 rounded-lg" :href="/Files/+obj.ID+'.docx'" download="特殊試場需求申請表">特殊試場需求申請表</a> 
         ，若勾選「是」請於後續步驟拍照或掃描上傳附件五<span class="text-red-500 font-bold">核章後申請表</span>及相關佐證資料
      </div>
      <div class="w-full p-2 flex justify-start rounded bg-lightColor" :class="view_type" >
        <span v-for="obj_item in obj.ObjectItemData" class="m-2">
        <input type="radio" class="ml-1 "  v-bind:name="'obj_'+obj.ID" v-bind:id="'obj_item_'+obj_item.ID"  v-bind:value="obj_item.ID" v-model="obj_item.check" @change="objCancel(obj.ID,obj_item.ID)" :required="obj.Required"><label v-bind:for="'obj_item_'+obj_item.ID">{{obj_item.Suffix}}</label>
        <input type="text" class="w-[30%] min-w-[200PX] p-2 bg-lightColor " placeholder="請輸入..." v-if="obj_item.Is_Other" :disabled="!obj_item.check" v-model="obj_item.Other_Data" :required="obj.Required" autocomplete="new-user-typing-data">
        </span>
      </div>
    </div>
    

    <!-- 4 勾選方塊 -->
    <div class="w-full" v-bind:id="'object_'+obj.ID+'_div'" v-if="obj.Object_Type_ID==4">
      <span class="font-bold w-full text-base  2xl:text-lg  ">{{obj.Title}}<span v-if="obj.Required == true" class="text-red-600 text-xl font-bold">*</span>：</span>
      <div class="w-full p-2 flex justify-start rounded bg-lightColor" :class="view_type" >
        <span v-for="obj_item in obj.ObjectItemData" class="m-2">
          <input type="checkbox" class="ml-1" v-bind:id="'obj_item_'+obj_item.ID" v-bind:value="obj_item.ID" v-model="obj_item.check" @change="obj4Cancel(obj.ID,obj_item.ID)" :required="obj.Required"><label v-bind:for="'obj_item_'+obj_item.ID">{{obj_item.Suffix}}</label>
          <input type="text" class="w-[30%] min-w-[200PX] p-2 bg-lightColor " placeholder="請輸入..." v-bind:id="'text_'+obj_item.ID" v-if="obj_item.Is_Other" :disabled="!obj_item.check" v-model="obj_item.Other_Data" autocomplete="new-user-typing-data">
          </span>
      </div>
    </div>
  

    <!-- 5 下拉式選單 -->
    <div class="w-full" v-bind:id="'object_'+obj.ID+'_div'" v-if="obj.Object_Type_ID==5">
      <span class="font-bold w-full text-base 2xl:text-lg ">{{obj.Title}}
        <span v-if="remind" v-html="remind"></span>
        <span v-if="obj.Required == true" class="text-red-600 text-xl font-bold">*</span>
        ：
      </span>
      <div class="w-full p-0  rounded bg-lightColor">
        <select :key="key" @change="CheckLink(obj.check)" v-if="obj.check != 99999999" v-model="obj.check"  class="w-full px-4 rounded bg-lightColor"  :required="obj.Required">
            <option  v-for="obj_item in obj.ObjectItemData" v-if="obj_item.Show" :value="obj_item.ID" >{{obj_item.Suffix}}</option> 
        </select>
        <input type="text" class="w-full min-w-[200PX] p-4 bg-lightColor " v-model="obj.Other_Data" v-if="other && obj.check==99999999" placeholder="請輸入..." :required="obj.Required" autocomplete="new-user-typing-data">
        <!-- <div v-if="obj.check != 99999999" class="absolute right-3 top-[50%] translate-y-[-50%]">
          <i class="fa-solid fa-caret-down"></i>
        </div> -->
      </div>
    </div>

    <!-- 6 檔案上傳 -->
    <div class="w-[95%] font-bold text-lg bg-lightColor min-h-[100px] rounded-2xl p-2" v-bind:id="'object_' + obj.ID + '_div'" v-if="obj.Object_Type_ID==6">
    <div class="flex flex-col lg:flex-row justify-between">
      <div class="w-[85%]" v-html="index+'.'+obj.Title"></div>
      <div class="w-[15%]md:mt-2">
      <a v-if="obj.ID != 600003002 && obj.ID != 500002001 && obj.ID != 100003003 && obj.ID != 200003003 &&  obj.ID != 300003003  &&  obj.ID != 400003004 && obj.ID != 600003004 && obj.ID != 600003005 && obj.ID != 500002003 && obj.ID != 500002004 && obj.ID != 100003002 && obj.ID != 200003002 && obj.ID != 300003002  && obj.ID != 400003002 && obj.ID != 500002005 && obj.ID != 600003006 " 
      class=" p-2 text-white text-[0.5rem] bg-defaultColor m-1 rounded-lg" :href="/Files/+obj.ID+'.docx'" :download="obj.Info" ><i class="far fa-file-alt mr-2"></i>空白格式下載</a>
      </div>
    </div>
     <div class="text-red-700 mb-2">可同時上傳多個檔案(請注意！！單個檔案大小不可以超過200MB！！)</div>
     <div>
      <a class="p-2 text-white bg-defaultColor m-1 rounded-lg"  href="javascript:void(0)" @click="UploadFile(obj)">
          <i class="fa fa-solid fa-file-upload"></i>
          <span class="text">檔案上傳</span>
      </a>
     </div>
     
     <div class="text-base mt-3" v-if="obj.SurveyFiles.length>0">
        <span>已上傳的檔案列表：</span>
        <div v-for="file,index in obj.SurveyFiles">
        <a class="m-1" :download="file.File_Name" :href="'api/Step/ShowImage/'+file.Real_Name">{{file.File_Name}}</a> <button @click="DeleteFile(file.ID)" class="text-red-700 "><i class="fa-solid fa-trash"></i></button>
        </div>
     </div>
    </div>

    <!-- 8 圖片上傳 -->
    <div class="min-h-[276px] min-w-[264px] lg:min-h-0 lg:min-w-0  lg:w-full lg:h-full" v-bind:id="'object_' + obj.ID + '_div'" v-if="obj.Object_Type_ID==8">
         <div class="w-full h-[75%]">  <vueCropper
         ref="cropper"
         :img="option.img"
         :outputSize="option.size"
         :outputType="option.outputType"
          :autoCrop="option.autoCrop"
          :autoCropWidth="option.autoCropWidth"
          :autoCropHeight="option.autoCropHeight"
          :canMoveBox="option.canMoveBox"
          :canMove="option.canMove"
          :fixedBox="true"
          :infoTrue="false"
          :info="false"
          :centerBox="true"
       ></vueCropper></div>
       <div class="w-full text-center text-red-700">圖片拖曳或使用滾輪放大調整位置</div>
       <div class="flex m-1 text-sm justify-around items-center">
       <button class="bg-defaultColor h-[30px] p-1 rounded-md text-white text-sm" @click="uploads">1.選擇照片</button>
       <button @click="SaveImg('blob',obj.ID,obj)" class="bg-defaultColor h-[30px] text-white p-1 rounded-md text-sm">2.確定上傳檔案</button>
       </div>
       
       <div xlass="z-50" v-html="option.msg"></div>
       <input type="file" id="uploads" style="position:absolute; clip:rect(0 0 0 0);" accept="image/png, image/jpeg, image/jpg" @change="uploadImg($event, 1)">
    </div>
    <!-- 10 日期選擇器 民國年 -->
    <div class="w-full" v-bind:id="'object_'+obj.ID+'_div'" v-if="obj.Object_Type_ID==10">
      <span class="font-bold w-full text-base 2xl:text-lg  ">{{obj.Title}}<span v-if="remind" v-html="remind"></span><span v-if="obj.Required == true" class="text-red-600 text-xl font-bold">*</span>：</span>
      <div>
      <date-picker :obj="obj" class="py-3"></date-picker>
    </div>
    

  </div>
</template>
</div>
    `;

//撰寫一個compornent date-picker 可以自動更新v-model
Vue.component("date-picker", {
  props: {
    obj: Object,
  },
  mounted() {
    let my = this;
    let option = {
      ROC: true,
      DefaultDate: this.obj.Data != "" ? this.obj.Data : null,
      TimePick: false,
      ButtonText: "尚未選擇",
      Format: "民國yyyy年MM月dd日",
      YearRangeStart: 25,
      OnChange: function (Pick) {
        my.obj.Data = Pick.String;
      },
    };

    console.log(option.DefaultDate);

    if (SchoolType == 1) {
      // !@#
      option["DisabledAbove"] = "2014-09-01";
      option["DisabledBelow"] = "2017-09-02";
      option["DisabledMiddleAbove"] = "2015-09-01";
      option["DisabledMiddleBelow"] = "2016-09-02";
      option["DefaultDate"] =
        this.obj.Data != "" && this.obj.Data != undefined
          ? this.obj.Data
          : "2014-09-01";
      option["YearRangeStart"] = 3;
      option["YearRangeEnd"] = 3;
    }

    $("#TimePicker_" + this.obj.ID).TimeSetting(option);
  },
  template: `<div class=" w-full p-2 rounded bg-lightColor">
  <div :id="'TimePicker_'+obj.ID"></div>
</div>`,
});

Vue.component("s-object", {
  props: [
    "option",
    "index",
    "step_data",
    "user_id",
    "inupt_type",
    "year_range",
    "view_type",
    "view_size",
    "view_un_select",
    "view_pos",
    "is_simple",
    "is_disabled",
    "prefix",
    "suffix",
    "step_object_id",
    "callback",
    "result_id",
    "refreshed_bs_select",
    "link_object_id",
    "default_value",
    "remind",
    "userdata",
  ],
  template: s_object_html,
  data: function () {
    return {
      just_view: false,
      obj_is_show: true,
      obj_data: null,
      type: "text",
      v_type: 0,
      v_pos: 0,
      v_un_select: false,
      simple: false,
      disabled_status: false,
      is_manager: false,
      select_year: new Date().getFullYear(),
      select_month: new Date().getMonth() + 1,
      select_day: new Date().getDate(),
      v_year_range: 100,
      WarningMessage: "",
      columnSettings: [],
      dataSettings: [],
      uploadStatus: false,
      key: 0,
      other: false,
      SchoolType: SchoolType,
    };
  },
  methods: {
    ReturnObj: function (_id) {
      let my = this;
      if (typeof _id == "undefined") _id = my.ReturnId();
      let data = Enumerable.from(my.step_data.ObjectData)
        .where("$.ID==" + _id)
        .firstOrDefault();

      if (data == null) return null;
      return data;
    },
    ReturnId: function () {
      return parseInt(this.step_object_id);
    },
    UploadFile: function (_item) {
      let my = this;
      let UrlInfo = new url();
      let User_ID = UrlInfo.query.u_id;
      let dyFile = document.createElement("input");
      dyFile.type = "file";
      dyFile.accept = Enumerable.from(
        "JPG;BMP;PNG;PDF;XLSX;CSV;XLS;DOC;DOCX;".split(";")
      )
        .where('$!=""')
        .select('"." + $')
        .toArray();
      dyFile.multiple = true;
      dyFile.onchange = function () {
        if (dyFile.files.length == 0) return;
        let tmpForm = new FormData();
        for (var i = 0; i < this.files.length; i++) {
          if (this.files[i].size / 102400 > 200) {
            alertBox({
              Html: `<span class="text-red-700">「${this.files[i].name}」單個檔案大小不可以超過200MB</span>`,
            });
            return false;
          }
          tmpForm.append("File", this.files[i]);
        }

        let _Url =
          BaseUrl +
          `/api/UploadFile/Upload/${my.step_data.ID}/${_item.ID}/${my.step_data.Subject_ID}/${User_ID}`;

        axios({ method: "POST", url: _Url, data: tmpForm })
          .then(function (res) {
            if (!res.data.Success) throw new Error(res.data.Message);

            alertBox({
              Html: `<span class="text-green-700">上傳完成！！</span>`,
              OnOK: function () {
                location.reload();
              },
            });
          })
          .catch(function (err) {
            alertBox({
              Html: `<span class="text-red-700">${err}</span>`,
            });
          });
      };

      dyFile.click();
    },
    DeleteFile: function (_FileID) {
      let my = this;
      let UrlInfo = new url();
      let User_ID = UrlInfo.query.u_id;
      let _Url =
        BaseUrl +
        `/api/UploadFile/Delete/${my.step_data.ID}/${_FileID}/${my.step_data.Subject_ID}/${User_ID}`;

      axios
        .get(_Url)
        .then(function (res) {
          if (!res.data.Success) {
            throw new Error(res.data.Message);
          }

          alertBox({
            Html: `<span class="text-green-700">刪除完成！！</span>`,
            OnOK: function () {
              location.reload();
            },
          });
        })
        .catch(function (err) {
          alertBox({
            Html: `<span class="text-red-700">${err}</span>`,
          });
        });
    },
    uploadImg(e, num) {
      var file = e.target.files[0];
      if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(e.target.value)) {
        alert("圖片必須是,jpeg,jpg,png，的其中一種");
        return false;
      }
      var reader = new FileReader();
      reader.onload = (e) => {
        let data;
        if (typeof e.target.result === "object") {
          data = window.URL.createObjectURL(new Blob([e.target.result]));
        } else {
          data = e.target.result;
        }
        if (num === 1) {
          this.option.img = data;
        } else if (num === 2) {
          this.example2.img = data;
        }
      };
      reader.readAsArrayBuffer(file);
    },
    uploads() {
      document.getElementById("uploads").click();
    },
    SaveImg(type, obj_ID, obj) {
      let my = this;
      let UrlInfo = new url();
      let User_ID = UrlInfo.query.u_id;
      if (type === "blob") {
        if (my.option.img == "") {
          alertBox({
            Html: `<span class="text-red-500">無照片可上傳(請先選擇照片後再試)</span>`,
          });
          return false;
        }
        this.$refs.cropper[0].getCropBlob((data) => {
          let _url = "";
          _url +=
            BaseUrl +
            `/api/Step/UploadImage/${my.step_data.ID}/${obj_ID}/${my.step_data.Subject_ID}/${User_ID}`;

          let tmpForm = new FormData();
          tmpForm.append("File", data, "大頭照.png");

          axios({ method: "POST", url: _url, data: tmpForm })
            .then(function (res) {
              if (!res.data.Success) {
                throw new Error(res.data.Message);
              }
              my.option.msg =
                '<span class="text-green-900">上傳完成！！</span>';
              Enumerable.from(my.step_data.ObjectData)
                .where(function (object) {
                  return object.ID == my.step_object_id;
                })
                .forEach(function (object) {
                  object.Data = "大頭照.png";
                  object.Real_Filename = res.data.Data;
                });
            })
            .catch(function (err) {
              my.option.msg = `<span class="text-red-500">上傳異常！！</span>`;
              alertBox({
                Html: `<span class="text-red-500">${err}</span>`,
              });
            });
        });
      }
    },
    CheckLink(obj_Item_ID) {
      let my = this;
      if (my.link_object_id != undefined) {
        let Select_Dom = my.$root.$refs.main.$refs[`obj_${my.link_object_id}`];
        let Main_Obj = my.ReturnObj(my.step_object_id);
        let Select_Item = Enumerable.from(Main_Obj.ObjectItemData)
          .where(function (item) {
            return item.ID == obj_Item_ID;
          })
          .firstOrDefault();

        if (Select_Item.Suffix.includes("其他") && Select_Dom != undefined) {
          Select_Dom.other = true;
          Enumerable.from(Select_Dom.step_data.ObjectData)
            .where(function (x) {
              return x.Object_Type_ID == 5;
            })
            .forEach(function (x) {
              Enumerable.from(x.ObjectItemData).forEach(function (y) {
                if (y.Link_Step_Object_Item_ID != 0) {
                  y.Show =
                    y.Link_Step_Object_Item_ID == obj_Item_ID ? true : false;
                  x.check = 99999999;
                }
              });
            });
          Select_Dom.key += 1;
        } else if (Select_Dom != undefined) {
          Select_Dom.other = false;
          Enumerable.from(Select_Dom.step_data.ObjectData)
            .where(function (x) {
              return x.Object_Type_ID == 5;
            })
            .forEach(function (x) {
              Enumerable.from(x.ObjectItemData).forEach(function (y) {
                if (y.Link_Step_Object_Item_ID != 0) {
                  y.Show =
                    y.Link_Step_Object_Item_ID == obj_Item_ID ? true : false;
                  x.check = undefined;
                  x.Other_Data = "";
                }
              });
            });
          Select_Dom.key += 1;
        }
      }
    },
    objCancel: function (obj_ID, item_ID) {
      let my = this;
      Enumerable.from(my.step_data.ObjectData)
        .where(function (x) {
          return x.ID == obj_ID;
        })
        .forEach(function (obj) {
          obj.ObjectItemData.forEach(function (item) {
            if (item.ID != item_ID) {
              item.Other_Data = "";
              item.check = "";
            }
          });
        });
    },
    obj4Cancel: function (obj_ID, item_ID) {
      let my = this;
      Enumerable.from(my.step_data.ObjectData)
        .where(function (x) {
          return x.ID == obj_ID;
        })
        .forEach(function (obj) {
          obj.ObjectItemData.forEach(function (item) {
            if (item.check == false && item_ID == item.ID) {
              item.Other_Data = "";
              item.check = "";
            }
          });
        });
    },
    ///寫死(處理國小一般智能智力測驗欄位)
    objitemCancel: function (item_ID) {
      let my = this;

      Enumerable.from(my.step_data.ObjectData)
        .where(function (x) {
          return x.ID == 500001030 || x.ID == 500001031 || x.ID == 500001032;
        })
        .toArray()
        .forEach(function (it) {
          let IDs = "#check_" + it.ID;
          document.querySelector(IDs).value = "";
        });
    },
    //日期轉換
    toMinguoYear(gregorianYear) {
      return parseInt(gregorianYear, 10) - 1911;
    },
    toGregorianYear(minguoYear) {
      return parseInt(minguoYear, 10) + 1911;
    },
    dateFormatter(date) {
      if (date == null || date === "") return "";
      const gregorianDate = moment(date).format("YYYY-MM-DD"); // 指定仅日期格式
      const gregorianYear = moment(date).year();
      const minguoDate = gregorianDate.replace(
        gregorianYear.toString(),
        this.toMinguoYear(gregorianYear).toString()
      );
      return minguoDate;
    },
    dateParser(date) {
      if (date == null || date === "") return null;
      const gregorianYear = moment(date, "YYYY-MM-DD").year(); // 指定仅日期格式
      const minguoYear = this.toGregorianYear(gregorianYear);
      const parsedDate = date.replace(
        gregorianYear.toString(),
        minguoYear.toString()
      );
      return moment(parsedDate, "YYYY-MM-DD").toDate(); // 指定仅日期格式
    },
  },
  mounted: function () {
    let my = this;
    my.$nextTick(function () {
      my.disabled_status =
        typeof my.is_disabled == "undefined" ? false : my.is_disabled;
      my.obj_data = my.ReturnObj();
      if (my.obj_data == null) return;
    });
  },
  ///寫死(處理國中小就讀區域其他欄位)
  updated: function () {
    let my = this;
    let Select_Dom = my.$root.$refs.main.$refs[`obj_${my.step_object_id}`];
    if (Select_Dom != undefined) {
      Enumerable.from(Select_Dom.step_data.ObjectData).forEach(function (x) {
        if (x.check == 99999999) {
          my.other = true;
        }

        //日期初始化
        if (x.ID == 100000003) {
          x.Data == null ? "" : x.Data;
        }
      });
    }
  },
});

Vue.component("alert-object", {
  props: ["message", "type"],
  template: "",
  data: function () {
    return {};
  },
  mounted: function () {
    let my = this;
    if (my.message) {
      alertBox({
        Html: my.message,
        Type: my.type ? my.type : "C",
      });
    }
  },
});
